:root {
  --stock: #40576f;
}

.search {
  margin: 10px 0 20px;
}

.search .stock-input {
  margin: 5px auto;
  width: 20vw;
  min-width: 220px;
}

.search .search-button {
  background-color: var(--stock);
  color: white;
  margin: 5px;
}

.chips .MuiBadge-badge {
  color: var(--stock);
}

.chips .badge {
  margin: 10px;
}

.chips .chip {
  background-color: var(--stock);
  color: white;
  margin: 5px;
}

.chips .chip.active-chip {
  background-color: var(--primary);
}

.tweets {
  width: 60vw;
  margin: 0 auto;
}

.tweets .card {
  border-radius: 0;
  text-align: left;
}

.tweets .card .MuiCardContent-root div {
  display: grid;
  grid-template-areas:
    'picture namearea'
    'picture text';
  grid-template-columns: 20% 80%;
}

.tweets .picture {
  grid-area: picture;
  display: grid;
  justify-content: center;
}

.tweets .namearea {
  grid-area: namearea;
}

.tweets .name {
  font-weight: bold;
}

.tweets .username {
  color: rgb(101, 119, 134);
}

.tweets .text {
  grid-area: text;
}

@media only screen and (max-width: 960px) {
  .tweets {
    width: 75vw;
  }

  .search .stock-input {
    width: 75vw;
  }
}

@media only screen and (max-width: 450px) {
  .tweets {
    width: 90vw;
  }

  .tweets .picture {
    justify-content: end;
  }

  .search .stock-input {
    width: 85vw;
  }
}
