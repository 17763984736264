.app-portfolio {
  display: grid;
  grid-template-columns: 99%;
  justify-content: space-around;
}

.app-portfolio .portfolio-card {
  animation: fade-in 0.5s linear;
}

.portfolio-display .slide {
  display: flex;
  flex-flow: wrap;
  max-height: 650px;
  overflow-x: hidden;
  width: 100%;
  justify-content: center;
}

.portfolio-display .slide .slide-image {
  width: 100%;
  display: block;
}

.portfolio-display .slide img {
  max-height: 600px;
  box-shadow: var(--greyBoxShadow);
  margin-bottom: 5px;
}

.portfolio-display button {
  background-color: var(--primary);
  color: white;
  width: 150px;
}

.portfolio-display button:hover {
  background-color: var(--primary);
}

.portfolio-display div {
  background-color: white;
}

.app-portfolio .portfolio-display .MuiMobileStepper-dot {
  background-color: var(--primary);
}

.app-portfolio
  .portfolio-display
  .MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  background-color: blue;
}

@media only screen and (max-width: 960px) {
  .portfolio-display button {
    width: 120px;
  }

  .app-portfolio .MuiMobileStepper-dot {
    height: 6px;
    width: 6px;
  }
}

@media only screen and (max-width: 450px) {
  .app-portfolio .MuiMobileStepper-dots {
    display: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
