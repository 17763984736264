.app-home {
  display: grid;
  grid-template-areas:
    'bio highlights'
    'bio skills'
    'experience experience'
    'education education';
  grid-template-columns: 48% 48%;
  justify-content: space-around;
}

.app-home .home-card {
  margin: 10px 5px;
  padding: 5px;
}

.app-home .home-card h3 {
  margin: 4px 0;
}

.app-home .bio-card {
  grid-area: bio;
  padding-top: 10px;
}

.app-home .bio-card .media-area {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: var(--greyBoxShadow);
}

.app-home .bio-card .card-buttons {
  display: grid;
  grid-template-columns: auto auto;
}

.app-home .highlights-card {
  grid-area: highlights;
}

.app-home .highlights-card ul {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.app-home .highlights-card ul a {
  text-decoration: none;
  display: list-item;
}

.app-home .highlights-card ul a:hover {
  background-color: var(--greyhighlight);
}

.app-home .highlights-card ul .MuiListItem-root.MuiListItem-gutters {
  display: none;
}

.app-home .highlights-card ul .MuiListItem-root.MuiListItem-gutters.linkset {
  display: block;
  text-align: center;
}

.app-home .highlights-card .linkset img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: var(--greyBoxShadow);
  margin-bottom: 10px;
}

.app-home .skills-card {
  grid-area: skills;
}

.app-home .skills-card ul {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.app-home .skills-card h3 {
  padding-bottom: 15px;
}

.app-home .experience-card {
  grid-area: experience;
}

.app-home .experience-card h3,
.app-home .education-card h3 {
  text-align: left;
}

.app-home .experience-card li,
.app-home .education-card li {
  padding-top: 2px;
  padding-bottom: 2px;
}

.app-home .experience-card li span,
.app-home .education-card li span {
  line-height: 1.4;
}

.app-home .experience-card .group {
  display: grid;
  grid-template-areas:
    'title title dates'
    'description description description'
    'jobTitle jobTitle jobTitle';
  margin: 10px auto;
}

.app-home .home-card .group .title {
  grid-area: title;
}

.app-home .home-card .group .dates {
  grid-area: dates;
  text-align: right;
}

.app-home .experience-card .group .description {
  grid-area: description;
  font-style: italic;
}

.app-home .experience-card .group .job-title {
  grid-area: jobTitle;
}

.app-home .experience-card .group .job-title span {
  font-weight: bold;
}

.app-home .experience-card .group .skills {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 7;
}

.app-home .home-card .group .skills .skill {
  margin: 2px 10px;
}

.app-home .experience-card .group .group-text {
  grid-column-start: 1;
  grid-column-end: 3;
}

.app-home .home-card .group-set-content {
  padding-bottom: 10px;
}

.app-home .home-card .group-set-nav {
  display: grid;
  grid-template-columns: 50% 50%;
}

.app-home .home-card .group-set-nav button {
  width: 150px;
}

.app-home .education-card {
  grid-area: education;
}

.app-home .education-card .group {
  display: grid;
  grid-template-areas: 'title title dates';
  grid-template-columns: 33% 33% 33%;
  margin: 10px auto;
  align-content: center;
}

.app-home .education-card .group .skills {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 7;
}

.app-home .education-card .group .group-text {
  grid-column-start: 1;
  grid-column-end: 3;
}

@media only screen and (max-width: 960px) {
  .app-home {
    grid-template-areas:
      'bio'
      'highlights'
      'skills'
      'experience'
      'education';
    grid-template-columns: 100%;
  }

  .app-home .skills-card ul {
    grid-template-columns: 50% 50%;
  }

  .app-home .experience-card .group .skills {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 10;
    grid-row-end: 10;
  }

  .app-home .experience-card .group .group-text {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .app-home .education-card .group .skills {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 8;
    grid-row-end: 8;
  }

  .app-home .education-card .group .group-text {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

@media only screen and (max-width: 450px) {
  .app-home .highlights-card ul {
    grid-template-columns: 100%;
  }

  .app-home .skills-card ul fieldset {
    padding: 0;
  }
}
