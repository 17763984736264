.xkcd .loading-logo {
  height: 70vh;
}

.slideshow {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: 32% 32% 32%;
  height: 74vh;
  margin: 0 5px;
}

.panel-card {
  height: 70vh;
}

.slideshow img {
  max-width: 100%;
  max-height: 50vh;
}

.xkcd-footer nav {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: 23% 23% 23% 23%;
}

.xkcd-footer nav button {
  width: 30%;
  margin: 0 auto;
  text-align: center; 
  padding: 10px 0;
  font-size: 17px;
  font-weight: bold;
  background-color: #6E7B91;
  color: white;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 7px;
  border: 0;
  font-variant: small-caps;
}

.credit {
  padding-top: 20px;
  font-size: 17px;
}

@media only screen and (max-width: 960px) {
  .xkcd .loading-logo {
    height: 50vh;
  }

  .slideshow {
    grid-template-columns: 100%;
    height: auto;
  }

  .xkcd-footer nav {
    grid-template-columns: 48% 48%;
    margin: 10px auto;
  }

  .xkcd-footer nav button {
    width: 50%; 
    margin: 10px auto;
  }
}

@media only screen and (max-width: 450px) {
  .xkcd .loading-logo {
    height: 30vh;
  }
}